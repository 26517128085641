import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { useCustomerToken } from 'hooks/useCustomerToken';
import { useLoginText } from 'hooks/useLoginText';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

import styles from './Header.module.css';

export const LoginItem = () => {
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);
  const { accessToken } = useCustomerToken();
  const { t } = useTranslation();
  const router = useRouter();

  const loginText = useLoginText();

  const getPageUrl = (pageName: string) => {
    return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`;
  };

  const loginHandler = () => {
    router.push(getPageUrl('/me/signin'));
  };

  return (
    <li className={styles['c-header__nav__list__item']}>
      {accessToken ? (
        <Link href={getPageUrl('/me/top')} legacyBehavior>
          {t('My Page')}
        </Link>
      ) : (
        <a onClick={loginHandler}>{loginText}</a>
      )}
    </li>
  );
};
