import * as React from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import '@nutmeglabs/falcon-ui/dist/esm/index.css';

import { selectHeaderDescriptionText } from 'lib/util/privateMarketplace';
import { isEssentialPagePath } from 'lib/util/isEssentialPagePath';
import { PMPFooterView } from 'components/PrivateMarketplace/PMPFooterView/PMPFooterView';
import { Header } from 'components/PrivateMarketplace/Header/Header';
import { useRouter } from 'next/router';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { ReduxState } from 'ducks';
import { FalconUIContextProvider } from 'components/FalconUIContextProvider/FalconUIContextProvider';
import { useHeaderDescriptionTagType } from 'hooks/useHeaderDescriptionTagType';

const CustomHeader = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Header));
const CustomFooter = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Footer));

type Props = {
  children: React.ReactNode;
};

const vibrantThemePathShouldShowHeader = (path: string): boolean => {
  if (isEssentialPagePath(path)) {
    return true;
  }

  if (path.includes('checkout')) {
    return true;
  }

  const matchResult = path.match(/top\/([^/]+)/);
  if (!matchResult) {
    return false;
  }

  if (matchResult[1] === 'products') {
    return false;
  }

  // bookmarked, popular, recommended, visited, category, and feature pages should all show
  // the header
  return true;
};

export const PrivateMarketplacePage = ({ children }: Props) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const shouldUseCustomTopPage = useSelector(
    (state: ReduxState) => state.server.settings.all?.is_custom_top_page_enabled
  );
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const operatingHoursRules = settings?.operating_hours_rules;
  let operatingHoursDescription = '';
  if (operatingHoursRules && operatingHoursRules.length > 0) {
    operatingHoursDescription = `${operatingHoursRules[0].start_time_local} - ${operatingHoursRules[0].end_time_local}`;
  }
  const topPageData = useSelector((state: ReduxState) => state.server.customTopPage.page);
  const shouldUseCustomHeaderFooter = shouldUseCustomTopPage && topPageData;
  const router = useRouter();
  const pageDescriptionTag = useHeaderDescriptionTagType();

  const shouldShowHeader =
    bookingWidgetTheme !== 'VIBRANT' || vibrantThemePathShouldShowHeader(router.asPath ?? '');

  const headerDescriptionText = useSelector(selectHeaderDescriptionText);

  return (
    <div className="base-wrap">
      <FalconUIContextProvider>
        {shouldShowHeader && shouldUseCustomHeaderFooter ? (
          <CustomHeader
            data={{
              ...topPageData,
              headerStyle: 'OPAQUE',
            }}
            pageDescriptionTag={pageDescriptionTag === 'h1' ? 'h1' : 'div'}
          />
        ) : (
          <>
            {pageDescriptionTag === 'h1' ? (
              <h1 className="base-h1">{headerDescriptionText}</h1>
            ) : (
              <div className="base-h1">{headerDescriptionText}</div>
            )}
            <Header />
          </>
        )}
        <main className="pmp-base-main">{children}</main>
        {shouldUseCustomHeaderFooter ? (
          <CustomFooter
            data={topPageData}
            orgInfo={{
              phone: settings?.supplier_phone ?? '',
              email: settings?.supplier_email ?? '',
              address: settings?.supplier_address ?? '',
              operatingHoursDescription,
              hideFooterJapaneseTermsLink:
                settings?.booking_widget_design_params?.hide_footer_japanese_terms_link ?? false,
              snsIds: settings?.sns_ids ?? [],
              showTravelAgencyRegistrationForm:
                settings?.reservation_payment_gateway_settings?.payment_gateway === 'GMO',
            }}
          />
        ) : (
          <PMPFooterView />
        )}
      </FalconUIContextProvider>
    </div>
  );
};
