import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import { selectIsStandardPlanActive } from 'lib/util/privateMarketplace';
import { Layout } from 'components/Layout/Layout';
import { PrivateMarketplacePage } from './PrivateMarketplacePage';
import { PopupMessage } from 'components/PopupMessage/PopupMessage';

type Props = {
  children: React.ReactNode;
};

export const PrivateMarketplaceLayout = ({ children }: Props) => {
  const isStandardPlanActive = useSelector(selectIsStandardPlanActive);

  const { t } = useTranslation();

  if (!isStandardPlanActive) {
    return (
      <Box mt={2} ml={2}>
        <h3>{t('Page not found')}</h3>
      </Box>
    );
  }

  return (
    <Layout>
      <PopupMessage />
      <PrivateMarketplacePage>{children}</PrivateMarketplacePage>
    </Layout>
  );
};
